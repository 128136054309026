<template> 
<footer>
  <div class="social-container">
    <ul class="social-icons">
      <li v-for="(social, index) in socialLinks" :key="index">
        <a :href="social.url" target="_blank"><i :class="social.icon"></i></a>
      </li>
      

    </ul>
</div> 

</footer>

</template>

<script>
import {footerSocials} from '../../config/socials'

export default{
    data() {
        return {
            socialLinks : footerSocials,
            copyrightName : "Tronando"
        }
    }
    ,updated() {
      console.log(this.$route.name)
    }

};
</script>

<style scoped lang="scss">
footer {
  box-shadow: 0px 0px 5px 0px black;
  z-index: 99;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 4rem;
  background-color: var(--theme-on-primary);
}

@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);

$timing        : 265ms;
$iconColor     : rgb(33, 33, 33);
$accent        : var(--text-color);
$gradient      : var(--text-color);

@mixin transformScale($size: 0.7) {
    transform: scale($size);
    -ms-transform: scale($size);
    -webkit-transform: scale($size);
}

.social-container {
  display: flex;
  
  justify-content: center;
  
}

.social-icons {
    padding: 0;
    list-style: none;
    margin: 0em;
  
    li {
        display: inline-block;
        margin: 0.15em;
        position: relative;
        font-size: 1.2em;

    }

    i {
        color: var(--theme-on-primary);
        position: absolute;
        top: 21px;
        left: 21px;
        transition: all $timing ease-out;
    }

    a {
        display: inline-block;
      
        &:before {
            @include transformScale();
            content: " ";
            width: 60px;
            height: 60px;
            border-radius: 100%;
            display: block;
            background: linear-gradient(45deg, $iconColor, $accent);
            transition: all $timing ease-out;
        }
        
        &:hover:before {
            transform: scale(0);
            transition: all $timing ease-in;
        }
        
        &:hover i {
            @include transformScale(1.7);
            color: $iconColor;
            background: -webkit-linear-gradient(45deg, $iconColor, $accent);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: all $timing ease-in;
        }
    }
  
}
</style>
