<template>

  <div class="normalView" v-show="!this.mobile">
    <div class="wrapper border">
    <img src="@/assets/cookie.png" alt="">
    <div class="content">
      <header>Permitir Cookies</header>
      <p>
        Este sitio web usa cookies para asegurar la mejor experiencia posible para el usuario.
      </p>
      <div class="buttons">
        <button class="item" type="button" @click="acceptCookies">Aceptar</button>
        <button class="item" type="button" @click="denyCookies">Denegar</button>
      </div>
      <div class="buttons2"><a class="item"  type="button" @click="openPP()">Más información</a> </div>
      
    </div>
  </div>
  </div>

  <div class="mobileView" v-show="this.mobile">
    <div class="cookie-consent-banner bg-light">
  <div class="cookie-consent-banner__inner">
    <div class="cookie-consent-banner__copy">
      <div class="cookie-consent-banner__header">Permitir Cookies</div>
      <div class="cookie-consent-banner__description">Este sitio web usa cookies para garantizar la mejor experiencia posible a nuestros usuarios. Para obtener mas información, lee nuestra <a class="item"  type="button" @click="openPP()">politica de privacidad</a> </div>
    </div>

    <div class="cookie-consent-banner__actions">
      <a href="#" class="cookie-consent-banner__cta" @click="acceptCookies">
        Aceptar
      </a>
      <a href="#" class="cookie-consent-banner__cta deny" @click="denyCookies">
        Denegar
      </a>
    </div>
  </div>
</div>
</div>
  
  </template>
  
  <script>
  export default {
    name: "CookieBanner",
    data() {
      return {
        mobile: true
      }
    },
    methods: {
      acceptCookies() {
        this.$posthog.opt_in_capturing();
        this.$emit("hideBanner")
      },
      denyCookies() {
        this.$posthog.opt_out_capturing();
        this.$emit("hideBanner")
      },
      openPP() {
            this.$router.push({ name: "pp" });
      },
      checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 950) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      return;
    },
    },
    created() {
      window.addEventListener("resize", this.checkScreen);
      this.checkScreen()
    }
  
  };
  </script>
  
  <style scoped lang="scss">
.mobileView {
  .cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  
  z-index: 999;
  box-sizing: border-box;
  width: 100%;
}

.cookie-consent-banner__actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;

}

.cookie-consent-banner__inner {     
  max-width: 960px;
  margin: 0 auto;
  padding: 32px 0;
  width: 80%;
}

.cookie-consent-banner__copy { 
  margin-bottom: 16px;
}

.cookie-consent-banner__header {
  margin-bottom: 8px;

  font-weight: normal;
  font-size: 1.2rem;
  line-height: 24px;
}

.cookie-consent-banner__description {
  font-weight: normal;
  color: #838F93;
  font-size: 0.9rem;
  line-height: 24px;
  width: 90%;
  margin: 0 auto;
}

.cookie-consent-banner__cta {
  box-sizing: border-box;
  display: inline-block;
  padding: 16px;  
  border-radius: 5px;
  
  background-color: var(--theme-on-secondary);
   
  color: var(--text-color);
  text-decoration: none;
  text-align: center;
  font-weight: normal;
  font-size: 1.1rem;
  line-height: 20px;
}
.deny {
  background-color: rgb(184, 184, 184);
}

.cookie-consent-banner__cta:hover {
  background-color: var(--theme-on-primary);
}



}

.normalView {
  .wrapper{
  position: fixed;
  bottom: 30px;
  z-index: 9999;
  left: 30px;
  max-width: 365px;
  background: #fff;
  padding: 25px 25px 30px 25px;
  border-radius: 15px;
  box-shadow: 2px 7px 14px -5px rgba(0,0,0,0.15);
  text-align: center
}
.wrapper.hide{
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8);
  transition: all 0.3s ease;
}
::selection{
  color: #fff;
  background: #FCBA7F;
}
.wrapper img{
  max-width: 90px;
}
.content header{
  font-size: 25px;
  font-weight: 600;
}
.content{
  margin-top: 10px;
}
.content p{
  color: #858585;
  margin: 5px 0 20px 0;
}
.content .buttons{
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons button{
  padding: 10px 20px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 5px;
  background: #FCBA7F;
  cursor: pointer;
  transition: all 0.3s ease;
}
.buttons button:hover{
  transform: scale(0.97);
}
.buttons .item{
  margin: 0 10px;
}

.buttons2 a {
  margin-top: 20px;
  color: #FCBA7F;
}

.buttons2 a:hover {
  text-decoration: underline;
}

}
  </style>