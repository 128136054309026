<template>
  <div class="content">
    <PostPage/>
  </div>
  
</template>

<script>
import PostPage from '@/components/posts/PostPage.vue';
export default {
  components: {PostPage}
}
</script>

<style scoped lang="scss">

</style>