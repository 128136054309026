import axios from "axios";

const URL = 'https://tronando-backend.cyclic.app'

/* BEEHIIV */

export const fetchStats = async () => {
  try {
    const response = await axios.get(`${URL}/api/publication/stats`)
    return response
  } catch (error) {
    return null
  }
}

export const fetchPosts = async (_page) => {
  try {

    const response = await axios.get(`${URL}/api/${_page}`);
    return response.data;
  
  } catch (error) {
    console.error("Error fetching posts:", error);
    return null;
  }
};

export const fetchPost = async (postId) => {
  try {
    console.log(`${URL}/api/post/${postId}`)
    const response = await axios.get(`${URL}/api/post/${postId}`)
    return response
  } catch (error) {
    console.error("Error fetching post:", error);
    return null;
  }
}

export const postSuscription = async (userEmail) => {
  try {
    const response = await axios.post(`${URL}/api/subscribe`, {
      email: userEmail
    })
    return response
  } catch (error) {
    console.error("Error creating subscribtion:", error);
    return null;
  }
}

export const getSuscription = async (email) => {
  try {
    const response = await axios.get(`${URL}/api/subscribe/${email}`)
    return response
  } catch (error) {
    console.error("Error getting subscribtion:", error);
    return null;
  }
}

/* IMAGES */

export const getImagesByName = async (postImage) => {
  try {
    const response = await axios.get(`${URL}/pexels/image/${postImage}`)
    return response
  } catch (error) {
    return null;
  }
}

/* MAILING */

export const sendContactMail = async (formInfo) => {
  try {
    const response = await axios.post(`${URL}/mail/contact`, formInfo)
    return response
  } catch (error) {
    return null
  }
}

export const sendSponsorMail = async (formInfo) => {
  try {
    const response = await axios.post(`${URL}/mail/sponsor`, formInfo)
    return response
  } catch (error) {
    return null
  }
}
