<template>
  <div class="container">
    <form ref="form" @submit.prevent="sendEmail">
      <h2>Contáctanos</h2>
      <input
        v-model="name"
        class="feedback-input"
        name="from_name"
        id="name"
        placeholder="Nombre"
        required
      />
      <input
        type="email"
        class="feedback-input"
        v-model="email"
        name="email_id"
        id="email"
        placeholder="Correo electrónico"
        required
      />
      <textarea
        id="message"
        class="feedback-input"
        v-model="message"
        rows="4"
        name="message"
        placeholder="¿En qué te podemos ayudar?"
        required
      ></textarea>
      <button type="submit"><i class="fa-solid fa-paper-plane"></i></button>
    </form>
    
  </div>
</template>

<script lang="js">

import {sendContactMail} from '@/utils/apiCalls'
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";


export default {
    data() {
      return {
        name: '',
        email: '',
        message: ''
      }
    },
    methods: {
      sendEmail() {
      try {
        const mailInfo = {
          name : this.name,
          email : this.email,
          message : this.message
        }
        sendContactMail(mailInfo)
        this.toggleModal("success");
      } catch (error) {
        this.toggleModal("error");
      }

      this.name = ''
      this.email = ''
      this.message = ''

    },
      toggleModal(status) {
      const $toast = useToast();
      switch (status) {
        case "success":
          $toast.open({
            message: `<b> ¡Hemos recibido tu mensaje! </b><br> <p>Nos pondremos en contacto contigo tan pronto como sea posible.<br>Y hasta entonces, Rock & Roll🤘🧠!</p>`,
            type: "success",
            duration: 10000,
            position: "top-right",
          });
          break;
        case "error":
          $toast.open({
            message: `<b> Error </b><br> <p>Ha ocurrido un error. Por favor, inténtelo más tarde</p>`,
            type: "error",
            duration: 5000,
            position: "top-right",
          });
          break;
      }
    },
  }
};
</script>

<style scoped lang="scss">


h2 {
  position: relative;
  padding: 0 0 10px;
  margin-bottom: 40px;
  margin-top: -50px;
}

h2:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  height: 4px;
  width: 50px;
  border-radius: 2px;
  background-color: var(--theme-on-primary);
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  
}

.feedback-input {
  color: var(--text-color);
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
  line-height: 22px;
  background-color: transparent;
  border: 1px solid #CAD3DB;
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
}

.feedback-input:focus {
  border: 1px solid var(--theme-on-primary);
}

textarea {
  height: 230px;
  line-height: 150%;
  resize: vertical;
}

[type="submit"] {
  width: 100%;
  background: var(--theme-on-primary);
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  color: var(--text-color);
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s;
  margin-top: -4px;
  font-weight: 200;
}
[type="submit"]:hover {
  background: var(--theme-surface-1);
}
</style>