<template>
  
  <!-- Tronando WEB -->
  <div v-show="!calculateIfLanding()">
      <Navigation/>
      <div class="content">
        <router-view/>
      </div>
      <Footer></Footer>
      <CookieBanner  @hideBanner="showBanner = false"  v-if="showBanner"/>
  </div>


  <!-- Landing pages -->
  <div v-show="calculateIfLanding()">
    <router-view/>
  </div>
 
</template>

<script>
import Navigation from "./components/core/Navbar.vue";
import Footer from "./components/core/Footer.vue";
import CookieBanner from "./components/core/CookieBanner.vue";

export default {
  name: 'App',
  data: function() {
    return {
      showBanner: !(this.$posthog.has_opted_out_capturing() || this.$posthog.has_opted_in_capturing())
    }
  },
  methods: {
    calculateIfLanding() {
      return this.$route.path.includes('/subscribe')
    }
  },
  components: {
    Navigation,
    Footer,
    CookieBanner
  }
};

</script>

<style lang="scss">

@import './styles/theme.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
* {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 100;
  
}
body {
  background-color: white;
  
}
.content {
  margin-top: 50px;
  
}

#app {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
}
  @media (max-width: 1200px) {
    .content {
    margin-top: 1rem;
  
    
  }
}
</style>
