export const faq = [
	{
        number: '1',
        id: 'faqHeading-1',
        target: '#faqCollapse-1',
        controll: 'faqCollapse-1',
		question: '¿Qué es Tronando?',
		answere: '-'
	},
 



];