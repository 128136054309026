<template>
  <div class="content">
    <div class="infoSponsors bg-light">
      <h1>
        ¡Queremos darte visibilidad! <i class="fa-solid fa-arrow-trend-up"></i>
      </h1>
      
      <p>
        Más que si <b>BTB</b> o <b>BTC</b>…en Tronando nos gusta hablar de <b>H2H</b> - <i>Human 2 Human</i> - y es que nuestros suscriptores tienen vida tanto personal como profesional. 
      </p>
      <br>
      <p>
        <u>Algunos datos: </u> <br>
        </p>
        
        <p>
        <b>Ciudades Top donde los leen</b>: dibujar podium: Madrid, Barcelona, Valencia
      </p>
      <p>

        <b>Perfil más repetido:</b> Jóvenes profesionales, hombres, de 29-39 años. 
        <br>Además, contamos con: 
        <br>- Más de… 3.000 empresarios (C-level)
        <br>- Más de… 1.000 estudiantes de negocios/ finanzas

      </p>
      
      <br>
      <p>
        ¿Te interesa? Queremos ayudarte.
        <br>Rellena este formulario para que podamos cotillearte un poco y te contactaremos :) 
      </p>
      <button class="btnForm" @click="() => TogglePopup('buttonTrigger')">
        Enviar solicitud
      </button>
    </div>
    <div class="stats">
      <h2>Algunos datos del alcance de Tronando ⚡</h2>
      <div class="statsFeed">
        <div class="statItem">
          <i class="fa-solid fa-users"></i>
          <h4>Suscriptores</h4>
          <div class="spinner" v-show="isLoading"></div>
          <h1 v-show="!isLoading">{{ subscriptions }}</h1>
        </div>

        <div class="statItem centerItem">
          <i class="fa-solid fa-envelope-open-text"></i>
          <h4>Ratio de apertura</h4>
          <div class="spinner" v-show="isLoading"></div>
          <h1 v-show="!isLoading">{{ averageOpenRate }}%</h1>
        </div>

        <div class="statItem">
          <i class="fa-solid fa-arrow-pointer"></i>
          <h4>Ratio clicks</h4>
          <div class="spinner" v-show="isLoading"></div>
          <h1 v-show="!isLoading">{{ averageClickRate }}%</h1>
        </div>
      </div>
    </div>
    <LogoScroll :logos="this.logos" :text="`Patrocinadores con los que hemos trabajado`"/>
    <Popup
      v-if="popupTriggers.buttonTrigger"
      :TogglePopup="() => TogglePopup('buttonTrigger')"
    >
    </Popup>
  </div>
</template>

<script>
import LogoScroll from "@/components/sections/LogoScroll.vue";
import { fetchStats } from "@/utils/apiCalls";
import Popup from "@/components/popups/SponsorFormPopup.vue";
import {logoWork} from '@/config/logoSlide.js'

import { ref } from "vue";
export default {
  data() {
    return {
      logos: logoWork,
      subscriptions: null,
      averageOpenRate: null,
      averageClickRate: null,
      isLoading: false,
    };
  },
  components: { Popup, LogoScroll  },
  methods: {
    async getStats() {
      this.isLoading = true;
      try {
        const response = await fetchStats();
        const stats = response.data.data.stats;
        this.subscriptions = stats.active_subscriptions;
        this.averageOpenRate = stats.average_open_rate;
        this.averageClickRate = stats.average_click_rate;
      } catch (error) {
        this.subscriptions = 0;
        this.averageOpenRate = 0;
        this.averageClickRate = 0;
      }
      this.isLoading = false;
    },
  },
 
  async created() {
    window.scrollTo(0, 0);
    await this.getStats();
  },
  setup() {
    document.title = "Tronando | Patrocinadores 💼";
    const popupTriggers = ref({
      buttonTrigger: false,
      timedTrigger: false,
    });

    const TogglePopup = (trigger) => {
      
      popupTriggers.value[trigger] = !popupTriggers.value[trigger];
    };
    return {
      Popup,
      popupTriggers,
      TogglePopup,
    };
  },
};
</script>

<style scoped lang="scss">
.content {
  color: var(--text-color);
  padding-top: 30px;
  margin-bottom:3rem;
  .btnForm {
    padding: 10px;
    border-radius: 5px;
    border: 1px black solid;
    background-color: var(--theme-on-primary);
    color: var(--text-color);
  }
  .infoSponsors {
    text-align: center;
    padding: 50px;
    h1 {
      margin-bottom: 70px;
      margin-top: 50px;
    }
    p {
      width: 70%;
      margin: 20px auto;
      span {
        background-color: var(--theme-on-primary);
        padding: 2px;
      }
      text-align: center;
      font-size: 1.4rem;
      b {
        color: #fda447;
 
      }
    }
  }
  .stats {
    padding: 50px;
    h2 {
      text-align: center;
      margin-bottom: 100px;
    }
    .statsFeed {
      h4 {
        font-weight: 600;
      }
      display: flex;
      justify-content: space-between;
      width: 50%;
      margin: 0 auto;

      .statItem {
        text-align: center;

        i {
          font-size: 70px;
          margin-bottom: 30px;
          color: var(--theme-on-primary);
        }
        h1 {
        }
      }
    }
  }
}
@media (max-width: 950px) {
  .content {
    margin-top: -60px;
    .stats {
      padding: 5px;
      h2 {
        font-size: 1.5rem;
        margin-top: 30px;
      }
    
    }
    .infoSponsors {
        padding: 0;
        
        p {
          
          text-align: justify;
          line-height: 2.2rem;
        }
        h1 {
          font-size: 2rem;
          padding-top: 70px;
          margin-bottom: 10px;
        }
        .btnForm {
          margin-bottom: 30px;
          width: 90%;
          font-weight: 800;
        }
      }
  }
}
@media (max-width: 1500px) {
  .statsFeed {
    h1 {
      font-weight: 200;
      font-size: 1.8rem;
      margin-bottom: 40px;
    }
    h4 {
      font-weight: 600;
      font-size: 1.6rem;
    }
    justify-content: space-evenly;
    width: 100%;
    flex-direction: column;
    margin-top: -60px;
    .statItem {
      text-align: center;

      i {
        font-size: 30px;
        margin-bottom: 10px;
        color: var(--theme-on-primary);
      }
    }
  }
}

.spinner {
  margin: 30px auto 100px auto;
  border: 4px solid rgb(253, 237, 71, 0.8);
  border-left-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>