<template>
  <section class="faq-section bg-light">
    <div class="container">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <h2 class="subscribe__title">FAQ</h2>
        </div>
        <div class="col-md-12">
          <div class="faq" id="accordion">
 
            <div class="card" v-for="(item, index) in faqList" :key="index">
              <div class="card-header" :id="item.id">
                <div class="mb-0">
                  <h5
                    class="faq-title"
                    data-toggle="collapse"
                    :data-target="item.target"
                    data-aria-expanded="true"
                    :data-aria-controls="item.controll"
                  >
                    <span class="badge">{{ item.number }}</span>{{ item.question }}
                  </h5>
                </div>
              </div>
              <div
                :id="item.controll"
                class="collapse"
                :aria-labelledby="item.id"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <p>
                    {{ item.answere }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {faq} from '@/config/faq' 
export default {
    data() {
        return {
            faqList: faq
        }
    }
};
</script>

<style>
.faq-section {
    padding-top: 50px;
    padding-bottom: 50px;

}
.subscribe__title {
	font-size: 25px;
  margin-top: -10px;
	margin-bottom: 50px;
  text-align: center;
}
.container {
   width: 100%;
}
.faq-title h2 {
  position: relative;
  margin-bottom: 45px;
  font-size: 32px;
  margin-top: -35px;
  margin-bottom: 50px;
  text-align: center;
  display: inline-block;

  line-height: 1;
}
.faq-title h2::before {
  content: "";
  position: absolute;
  left: 50%;
  width: 60px;
  height: 2px;
  background: var(--theme-on-primary);
  bottom: -25px;
  margin-left: -30px;
}
.faq-title p {
  padding: 0 190px;
  margin-bottom: 10px;
}

.faq {
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.faq .card {
  border: none;
  background: none;
  border-bottom: 1px dashed #CAD3DB;
}

.faq .card .card-header {
  padding: 0px;
  border: none;
  background: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.faq .card .card-header:hover {
  background: rgb(253, 237, 71, 0.1);
  padding-left: 10px;
}
.faq .card .card-header .faq-title {
  width: 100%;
  text-align: left;
  padding: 0px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 1px;
  color: #3b566e;
  text-decoration: none !important;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
  
}

.faq .card .card-header .faq-title .badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 14px;
  float: left;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  text-align: center;
  background: var(--theme-on-primary);
  color: var(--text-color);
  font-size: 12px;
  margin-right: 20px;
}

.faq .card .card-body {
  padding: 30px;
  padding-left: 35px;
  padding-bottom: 16px;
  font-weight: 400;
  font-size: 16px;
  color: var(--text-color);
  text-align: justify;
  line-height: 28px;
  letter-spacing: 1px;
  border-top: 1px solid #CAD3DB;
}

.faq .card .card-body p {
  margin-bottom: 14px;
}

@media (max-width: 991px) {
  .faq {
    margin-bottom: 30px;
  }
  .faq .card .card-header .faq-title {
    line-height: 26px;
    margin-top: 10px;
  }
  .faq .card .card-body { 
    text-align: left;
  }
}
</style>