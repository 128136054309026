<template>
  <div class="content">
    <h2 class="subscribe__title">Últimas publicaciones</h2>
    <div v-for="(post, index) in postList" :key="index" style="width: 100%;" class="fadeInDown">
      <PostInList :post="post" :isAlt="calculateAlt(index)"/>
    </div>
  <div class="btnContainer">
    <div class='spinner' v-show="isLoading"></div>
<button class="Btn" @click="loadData()" v-show="!isLoading" :disabled="isDisabled">
  
  <div class="sign"><i class="fa fa-refresh" aria-hidden="true"></i>
</div>
  
  <div class="text">Cargar más</div>
</button>

</div>
  
  </div>


  
</template>



<script>
import {fetchPosts} from '@/utils/apiCalls'
import PostInList from '@/components/posts/PostInList.vue'
export default {
    data() {
      return {
        postList: [],
        page: 1,
        isDisabled: false,
        isLoading: false,
        totalPages: 1
      }
    },
    components : {PostInList},
    methods : {
        async fetchData() {
            this.isLoading = true
           
            try {
                const res = await fetchPosts(this.page)
                this.postList.push(...res.data)
                this.totalPages = res.total_pages
            } catch (fetchError) {
                console.log(fetchError)
            }
            this.isLoading = false
        },
        loadData() {
            if (this.page < this.totalPages) {
                this.page = this.page + 1
                this.fetchData()
            } else {
                this.isDisabled = true
            }
        },
        calculateAlt(index) {
          return index % 2 == 0
        }

    },
    async mounted() {
        await this.fetchData()
        
    }
}
</script>

<style lang="scss" scoped> 

.Btn {
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 60px;
  height: 60px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: var(--theme-on-primary);
}

/* plus sign */
.sign {
  width: 100%;
  transition-duration: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign svg {
  width: 17px;
}

.sign svg path {
  fill: white;
}
/* text */
.text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: var(--text-color);
  font-size: 1.2em;
  font-weight: 600;
  transition-duration: .3s;
}
/* hover effect on button width */
.Btn:hover {
  width: 300px;
  border-radius: 60px;
  transition-duration: .3s;
}

.Btn:hover .sign {
  width: 30%;
  transition-duration: .3s;
  padding-left: 20px;
}
/* hover effect button's text */
.Btn:hover .text {
  opacity: 1;
  width: 70%;
  transition-duration: .3s;
  padding-right: 10px;
}
/* button click effect*/
.Btn:active {
  transform: translate(1px ,1px);
}
//ANIMATIONS 
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInDown {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  }
  100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  }
  }
  @keyframes fadeInDown {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, -40%, 0);
  transform: translate3d(0, -40%, 0);
  }
  100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  }
  } 
.content {
  padding-top: 50px;
}

.subscribe__title {
	font-size: 32px;
	margin-bottom: 40px;
  text-align: center;
}
.loadmore {
    background: var(--theme-on-primary);
    text-align: center;
    padding: 10px;
    width: 200px;
    border-radius: 20px;
    margin: 0 auto;
    margin-bottom: 100px;
    font-size: 20px;
}

.btnContainer {
  margin-top: -30px;

  display: flex;
}


.loadmore:hover {
    cursor: pointer;
    background: var(--theme-surface-1);
}

.loadmore:disabled {
  cursor: default;
  opacity: 0.5;
}

.spinner {
    
  margin: 30px auto 100px auto;
  border: 4px solid rgb(253, 237, 71, 0.8);
  border-left-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 950px) {
  img, .subtitle {
    display: none;
  }
  .content {
  padding-top: 20px;
 }

 .subscribe__title {
	font-size: 25px;
	margin-bottom: 40px;
  margin-top: 20px;

  }
  .Btn {
    margin-top: 2.5rem;
  }
}


</style>