<template>
  <div class="content" >
    <LogoScroll :logos="this.logos" :text="`Nuestros suscriptores trabajan en...`" style="margin-bottom: 8rem;" />
    <h2 class="subscribe__title">¿Qué piensan de tronando?</h2>
    
    
    
    
    <div class="normalview">
      <div id="testimonialCarrousel" class="carousel slide" data-ride="carousel">
        
        <div class="carousel-inner">
          <div
            v-for="(testimonial, index) in testimonialList2"
            :key="index"
            class="carousel-item"
            :class="{ active: testimonial[0].active }"
          >
            <div class="card-wrapper">
              <div class="testimonial">
                <div class="testimonial-content">
                  <h5 class="title">{{ testimonial[0].author }}</h5>
                  <p></p>
                  <div class="testimonial-icon">
                    <img src="../../assets/logo-tronando.png" alt="" />
                  </div>
                  <div class="description">
					<p>
						{{ testimonial[0].text }}
					</p>
					<p>
						{{ testimonial[0].line1 }}
					</p>
					<p>
						{{ testimonial[0].line2 }}
					</p>
                  </div>
                </div>
              </div>
              <div class="testimonial">
                <div class="testimonial-content">
                  <h5 class="title">{{ testimonial[1].author }}</h5>
                  <p></p>
                  <div class="testimonial-icon">
                    <img src="../../assets/logo-tronando.png" alt="" />
                  </div>
                  <div class="description">
					<p>
						{{ testimonial[1].text }}
					</p>
					<p>
						{{ testimonial[1].line1 }}
					</p>
					<p>
						{{ testimonial[1].line2 }}
					</p>
                  </div>
                </div>
              </div>
              <div class="testimonial">
                <div class="testimonial-content">
                  <h5 class="title">{{ testimonial[2].author }}</h5>
                  <p></p>
                  <div class="testimonial-icon">
                    <img src="../../assets/logo-tronando.png" alt="" />
                  </div>
                  <div class="description">
					<p>
						{{ testimonial[2].text }}
					</p>
					<p>
						{{ testimonial[2].line1 }}
					</p>
					<p>
						{{ testimonial[2].line2 }}
					</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       

        <a class="carousel-control-prev" href="#testimonialCarrousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#testimonialCarrousel" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
      </div>
    </div>





    <div class="mobileview">
      <div id="testimonialCarrousel2" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div
            v-for="(testimonial, index) in testimonialList"
            :key="index"
            class="carousel-item"
            :class="{ active: testimonial.active }"
          >
            <div class="card-wrapper">
              <div class="testimonial">
                <div class="testimonial-content">
                  <h5 class="title">{{ testimonial.author }}</h5>
                  <p></p>
                  <div class="testimonial-icon">
                    <img src="../../assets/logo-tronando.png" alt="" />
                  </div>
                  <div class="description">
					<p>
						{{ testimonial.text }}
					</p>
					<p>
						{{ testimonial.line1 }}
					</p>
					<p>
						{{ testimonial.line2 }}
					</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev" href="#testimonialCarrousel2" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#testimonialCarrousel2" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
      </div>
      
    </div>

  
  </div>
</template>
         
<script>
import {logoUsers} from '@/config/logoSlide.js'
import LogoScroll from "../sections/LogoScroll.vue";
import { testimonials } from "../../config/testimonials";
import { testimonials2 } from "../../config/testimonials";

export default {
  name: "testimonial",
  data() {
    return {
      logos: logoUsers,
      testimonialList: testimonials,
      testimonialList2: testimonials2,
    };
  },
  components: {
    LogoScroll
  }
};
</script>
         
<style lang="scss" scoped>
:root {
  --main-color: #fded47;
}
.carousel-control-next,
.carousel-control-prev {
    filter: invert(100%);
    position: absolute;
    top: -300px;
}
.subscribe__title {
	font-size: 32px;
	margin-bottom: 40px;
  text-align: center;
}

.content {
  padding-top: 20px;
  margin-bottom: 3rem;
}
.card-wrapper {
  width: 80%;
  height: 550px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.testimonial {
  margin: 0 20px 40px;
  width: 30%;
}
.testimonial .testimonial-content {
  padding: 35px 25px 35px 50px;
  margin-bottom: 1.5rem;
  border: 1px solid #CAD3DB;
  position: relative;
}
ul {
  list-style: decimal;
}
.testimonial-content .testimonial-icon {
  background-color: var(--back-color);
  position: absolute;
  top: 10px;
  left: -30px;
  img {
    width: 70px;
    height: 70px;
  }
}



.testimonial .description {
  font-size: 15px;
  color: #6b6b6b;
  line-height: 30px;
  margin: 0;
}

.testimonial .title {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: var(--text-color);
  text-transform: capitalize;
  letter-spacing: 1px;
  margin: 0 0 5px 0;
}

@media (min-width: 1500px) {
  .mobileview {
    display: none;
  }
  .normalview {
    display: contents;
  }
}
@media (min-width: 320px) and (max-width: 1500px) {
  .testimonial {
    width: 70%;
  }
  .card-wrapper { 
    height: 100%;
  }
  .carousel-control-next,
.carousel-control-prev {
    
    top: -80px;
    
}

  .content {
  padding-top: 20px;
 }
  .card-wrapper {
    width: 100%;
    flex-wrap: nowrap;
  }

  .mobileview {
    display: contents;
  }

  .subscribe__title {
  
	font-size: 23px;
  margin-top: 5px;
	margin-bottom: 45px;



}
  .normalview {
    display: none;
  }
}
</style>