export const testimonials = [
    {
        active: true,
        text: "Mi one stop los lunes donde lo tienes todo para ponerte al día del mundo. Además de divertido y ameno.",
        author: "F.L, Investment Banking Associate en J.P. Morgan"
    },
    {
        text: "Nunca tengo tiempo de ver las noticias. Gracias a Tronando sé qué está pasando sin ser pesado. Un 12 sobre 10! ",
        author: "P.P, CMO en Oratrex"
    },
    {
        text: `Me gusta Tronando porque :`,
        line1: `1)      Ofrece datos y una visión económica global de una manera desenfadada.`,
        line2: `2)      Transmite la actualidad financiera de una manera intuitiva y accesible para cualquiera.`,
        author: "J.C, Gestor Patrimonial en atl Capital"
    },
    {
        text: "Con unos pocos minutos todos los lunes basta para estar actualizado y tener una visión rápida del mundo.",
        line1: `Esencial para aquellos que seguimos de cerca la macro. También para toda la gente joven que quiere comenzar a informarse sobre lo que ocurre en el mundo de forma objetiva y con un toque divertido que acerca posturas.`,
        line2: `Plena confianza en el proyecto. No tengo dudas de que Tronando en poco tiempo será un referente de información económica en España.`,
        author: "J.N, M&A Analyst Intern en Implica Corporate Finance"
    },
    {
        text: "Me encanta tronando porque explica e informa sobre temas económicos y de empresa de una manera muy dinámica y diferente. Es perfecto para mantenerse al día y siempre me saca una sonrisa ☺",
        author: "M.I, Senior Commercial Manager Marriott"
    },
    {
        text: "Tronando hace la economía divertida, quién más junta memes y gifs con finanzas y actualidad?",
        author: "A.I, International Business student, Value Investor"
    },
]

export const testimonials2 = [
    [
        {
            active: true,
            text: "Mi one stop los lunes donde lo tienes todo para ponerte al día del mundo. Además de divertido y ameno.",
            author: "F.L, Investment Banking Associate en J.P. Morgan"
        },
        {
            text: "Nunca tengo tiempo de ver las noticias. Gracias a Tronando sé qué está pasando sin ser pesado. Un 12 sobre 10! ",
            author: "P.P, CMO en Oratrex"
        },
        {
            text: `Me gusta Tronando porque :`,
            line1: `1)      Ofrece datos y una visión económica global de una manera desenfadada.`,
            line2: `2)      Transmite la actualidad financiera de una manera intuitiva y accesible para cualquiera.`,
            author: "J.C, Gestor Patrimonial en atl Capital"
        } 
    ],
    [
        {
            text: "Con unos pocos minutos todos los lunes basta para estar actualizado y tener una visión rápida del mundo.",
            line1: `Esencial para aquellos que seguimos de cerca la macro. También para toda la gente joven que quiere comenzar a informarse sobre lo que ocurre en el mundo de forma objetiva y con un toque divertido que acerca posturas.`,
            line2: `Plena confianza en el proyecto. No tengo dudas de que Tronando en poco tiempo será un referente de información económica en España.`,
            author: "J.N, M&A Analyst Intern en Implica Corporate Finance"
        },
        {
            text: "Me encanta tronando porque explica e informa sobre temas económicos y de empresa de una manera muy dinámica y diferente. Es perfecto para mantenerse al día y siempre me saca una sonrisa ☺",
            author: "M.I, Senior Commercial Manager Marriott"
        },
        {
            text: "Tronando hace la economía divertida, quién más junta memes y gifs con finanzas y actualidad?",
            author: "A.I, International Business student, Value Investor"
        }
    ]

]