export const nav = [
	{
		title: 'Inicio',
		slug: '/'
	},
	{
		title: 'Merch',
		slug: 'https://tronando.myspreadshop.es/'
	},
	{
		title: 'Sobre Nosotros',
		slug: '/aboutus'
	},
	{
		title: 'Contacto',
		slug: '/contactus'
	},
	{
		title: 'Patrocinadores',
		slug: '/sponsors'
	},
	{
		title: 'Blog',
		slug: '/blog'
	},
	{
		title: 'Política de Privacidad',
		slug: '/privacypol'
	}
	

];