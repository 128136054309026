import posthog from "posthog-js";
import globalVariables from "@/utils/globalVariables";

export default {
  install(app, options) {
    app.config.globalProperties.$posthog = posthog.init(
      globalVariables.posthog_api_key,
      {
        api_host: globalVariables.posthog_host,
      }
    );
  },
};