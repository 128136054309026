<template>
  <div class="fadeInDown">
    <div class="content" v-show="!mobile" :class="{widenScreen: secondRes, normalScreen: !secondRes} ">
 
 <div class="leftside">
   <div class="logo">
     <h3>Tronando</h3>
     <img src="@/assets/logo-tronando-empty-2.png" alt="">
     
    
   </div>
   <div class="body">
     <h2>Entiende los eventos clave que moldean el mundo con solo 1 email a la semana ⚡</h2>
     <p>Recibe tu email cada lunes con las noticias que mueven el mundo - Economía, mercados, energía y tecnología. No te quedes atrás ✌️.</p>
   </div>
   <div class="form">
     <SubscribeForm/>
   </div>
   <div class="bottomtext">
     <span><b>11,000+</b> suscriptores de compañías como <b>J.P.Morgan, Inditex, y Mercadona</b> eligen Tronando para informarse.</span>
   </div>
   <p class="read-more">
     <router-link to="/home">
     Déjame leerlo primero
    </router-link>
    <span style="float: right;" v-on:click="enableBanner()">Aceptar las cookies 🍪</span>
   </p>
 </div>
 <div class="rightside">
   <img src="@/assets/phone-landing.png">
 </div>

 
</div>
<div class="contentmobile" v-show="mobile">

   <div class="logo">
     
     <img src="@/assets/logo-tronando-empty-2.png" alt="">
     <h3>Tronando</h3>
    
   </div>
   <div class="body">
     <h2>Entiende los eventos clave que moldean el mundo ⚡</h2>
     <p>Recibe tu email cada lunes con las noticias que mueven el mundo - Economía, mercados, energía y tecnología. No te quedes atrás ✌️.</p>
   </div>
   <div class="form">
     <SubscribeForm/>
   </div>
   <div class="bottomtext">
     <span><b>11,000+</b> suscriptores de compañías como <b>J.P.Morgan, Inditex, y Mercadona</b> eligen Tronando para informarse.</span>
   </div>
   <p class="read-more">
     <router-link to="/home">
     Déjame leerlo primero

    </router-link>
    <div>
      <p v-on:click="enableBanner()" style="margin-top: 1rem;">Aceptar las cookies 🍪</p>
    </div>
   </p>
 </div>
 
  </div>

  <CookieBanner  @hideBanner="showBanner = false"  v-if="showBanner"/>

</template>

<script>
import SubscribeForm from '@/components/forms/SubscribeForm.vue';
import CookieBanner from '@/components/core/CookieBanner.vue';
export default {
  data() {
    return {
      mobile: null,
      secondRes: true,
      showBanner: false
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    enableBanner() {
      this.showBanner = !this.showBanner;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1200) {
        this.mobile = true;
        this.secondRes = false;
        return;
      } else if (this.windowWidth > 1200 && this.windowWidth < 1500 ) {
        this.secondRes = true;
        this.mobile = false;
        return;
        console.log('hola')
      } 
        this.mobile = false;
        this.secondRes = false;
        return;
      

      
    }
  },
components: {
  SubscribeForm,
  CookieBanner
}
}
</script>

<style scoped lang="scss">
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInDown {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  }
  100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  }
  }
  @keyframes fadeInDown {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, -40%, 0);
  transform: translate3d(0, -40%, 0);
  }
  100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  }
  } 

.widenScreen {
  width: 85%;

}
.normalScreen {
  width: 70%;
}
.read-more {
      cursor: pointer;
     
      margin-top: 10px;
      margin-bottom: 20px;
      a {
        color: var(--text-color);
        display: inline-block;
        position: relative;
        text-decoration: none;
      
        &:after {
          content: "\f061";
          font-family: FontAwesome;
          margin-left: -10px;
          opacity: 0;
          vertical-align: middle;
          transition: margin .3s, opacity .3s;
        }

        &:hover:after {
          margin-left: 5px;
          opacity: 1;
        }
      }
    }
 .bottomtext {
      
      margin-bottom: 1rem;
      b {
        font-weight: 800;
      }
    }
.content {
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-evenly;
 
  margin-top: 7rem;
  .bottomtext {
    font-size: 1.1em;
  }
  .leftside {
    .form {
  
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      h3 {
        color: var(--theme-on-primary);
        font-weight: 800;
        font-size: 2.3em;
        letter-spacing: 0.5rem;
    
      }
      img {
        height: 7em;
        width: 7em;
        margin-bottom: 2rem;
      }
    }
    .body {
      margin-top: 1rem;
      h2 {
        font-weight: 600;
        font-size: 2.5em;
      }
      p {
        font-size: 1.4em;
      }
    }
    .read-more {
      cursor: pointer;
     
      margin-top: 10px;
      margin-bottom: 20px;
      a {
        color: var(--text-color);
        display: inline-block;
        position: relative;
        text-decoration: none;
      
        &:after {
          content: "\f061";
          font-family: FontAwesome;
          margin-left: -10px;
          opacity: 0;
          vertical-align: middle;
          transition: margin .3s, opacity .3s;
        }

        &:hover:after {
          margin-left: 5px;
          opacity: 1;
        }
      }
    }
   
  }
  .rightside {
    margin: 3rem;
    img {
      width: 20em;
      height: 40em;
      
    }
  }

}

.contentmobile {
 .read-more {
  text-align: center;
 }
  text-align: justify;
  width: 90%;
    margin: 3rem auto;
    .bottomtext {
      font-size: 0.9em;
    
    }
    .form {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  .logo { 
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      color: var(--theme-on-primary);
    font-weight: 1000;
    text-align: center;
    }
    img {
      width: 5rem;
      height: 5rem;
      margin-bottom: 1rem;
    }
  }
  .body {
    p {
      font-size: 1.1em;
      
    }
    h2 {
      font-size: 1.9em;
      font-weight:400;
      margin-bottom: 1.5rem;
      text-align: center;
    }
  }
  
}


</style>
