import { createRouter, createWebHistory } from 'vue-router'

//Views
import Home from '@/views/Home.vue'
import ContactUs from '@/views/ContactUs.vue'
import AboutUs from '@/views/AboutUs.vue'
import SinglePost from '@/views/SinglePost.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import Sponsors from '@/views/Sponsors.vue'
import Blog from '@/views/Blog.vue'
//Landings
import Landing1 from '@/views/landings/Landing2.vue'
import Landing2 from '@/views/landings/Landing2.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: Home
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: ContactUs
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: AboutUs
  },
  {
    path: '/privacypol',
    name: 'pp',
    component: PrivacyPolicy
  },
  {
    path: '/post/:id',
    name: 'post',
    component: SinglePost,
    props: true
  },
  {
    path: '/sponsors',
    name: 'sponsors',
    component: Sponsors
  },
  {
    path: '/subscribe',
    name: 'landing',
    component: Landing1
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog
  }
  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
