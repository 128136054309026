export const footerSocials = [
	{
		name: 'Twitter',
		url: 'https://twitter.com/BelenBaixauli',
		icon: "fab fa-twitter",
	},
	{
		name: 'Landing',
		url: 'https://tronando.com/subscribe',
		icon: "fab fa-fly",
	},
	{
		name: 'LinkedIn',
		url: 'https://www.linkedin.com/in/belenbaixauli/',
		icon: "fab fa-linkedin",
	},
	{
		name: 'Instagram',
		url: 'https://www.instagram.com/tronando_newsletter/',
		icon: "fab fa-instagram",
	},
	{
		name: 'Tik Tok',
		url: 'https://www.tiktok.com/@tronando_newsletter',
		icon: "fab fa-tiktok",
	}
]


export const navSocials = [
	{
		name: 'Twitter',
		url: 'https://twitter.com/BelenBaixauli',
		icon: "fab fa-twitter",
	},
	{
		name: 'Instagram',
		url: 'https://www.instagram.com/tronando_newsletter/',
		icon: "fab fa-instagram",
	},
	{
		name: 'Tik Tok',
		url: 'https://www.tiktok.com/@tronando_newsletter',
		icon: "fab fa-tiktok",
	}
]
