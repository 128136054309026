<template>
  <div class="bg-light" style="padding: 20px; ">
    <div class="container" v-show="!mobile" > 
		<div class="contact-box" >
			<div class="left"></div>
      
			<div class="right">
				<ContactForm/>
			</div>
		</div>
		 
	</div>
</div>
  <div class="container" v-show="mobile"  style="margin-top: 10px;"> 
		<ContactForm/>
	</div>
  <div class="bottom-side-color border-top" style="padding: 20px;" > 
  <div class="bottom-side">
      <div class="address details">
        <i class="fas fa-map-marker-alt"></i>
        <div class="topic"><a href="https://lanzadera.es/">LANZADERA</a></div>

      </div>
      <div class="web details">
        <i class="fa-solid fa-globe"></i>
        <div class="topic"><a href="https://www.tronando.com/">WWW.TRONANDO.COM</a></div>

      </div>
      <div class="email details">
        <i class="fa-solid fa-envelope-open"></i>
        <div class="topic"><a href = "mailto: noticias@tronando.com">NOTICIAS@TRONANDO.COM</a></div>
      </div>

      <div class="social details">
        <i class="fa-solid fa-share-nodes"></i>
        <div class="topic">@TRONANDO_NEWSLETTER</div>
      </div>

	</div>	
</div>
</template>






<script>
import ContactForm from '@/components/forms/ContactForm.vue';
export default{
  data() {
    return {
      mobile: null
    }
  },
  components: {ContactForm},
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1000) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      return;
    }
  },
  setup() {
    window.scrollTo(0, 0)
    document.title = 'Tronando | Contacto ✉️'
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen()
  },
  
}
</script>
<style lang="scss" scoped>
.bottom-side-color {
  background-color: white;
}
.bottom-side {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 30px;
  
  a {
    text-decoration: none;
    font-size: 18px;
      font-weight: 500;
	    line-height: 3rem;
      color: var(--text-color);

  }
  a:hover {
    color: var(--theme-on-primary);
    border-bottom: 1px var(--theme-on-primary) solid;
  }
  .details {

    margin: 14px;
    text-align: center;
    i {
      font-size: 30px;
      color: var(--theme-on-primary);
      margin-bottom: 10px;
    }
    .topic {
      font-size: 18px;
      font-weight: 500;
	    line-height: 3rem;
      color: var(--text-color);
    }
  }
}



.container{
  margin-top: 80px;
  margin-bottom: 20px;
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 10px;
}

.container:after{
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-size: cover;
	z-index: -1;
}
.contact-box{
	width: 85%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #fff;
	box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.19);
}

.left{
	background: url("@/assets/lanzadera_hd.jpg") no-repeat center;

	background-size: cover;
	height: 100%;
}

@media (max-width: 950px) {
  .bottom-side-color {
  background-color: #f8f9fa;
}
    .contact-box{
        display: flex;
        flex-direction: column;
    }

        .container {
        margin-top: 80px;
        width: 100%;
        margin-bottom: 0;
    }

	.bottom-side {


    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    .details {
      
    }
}
	}
</style>