<template>
<div class="content" style="height: 100vh; overflow: hidden;">
  <iframe v-on:load="disableSpinner" class="iframeN" src="https://it195190.wixsite.com/tronando-blog/blog" frameborder="0"></iframe>
   <div class='spinner' v-show="isLoading"></div>
</div>

</template>

<script>
  export default {
    data() {
      return {
        isLoading: true
      }
    },
    setup() {
    document.title = 'Tronando | Blog ✍'
    window.scrollTo(0, 0)
  },
    methods: {
      disableSpinner() {
        this.isLoading = false
      }
  }
}
</script>

<style scoped>
  .iframeN {
    height: 100vh;
    width: 100% !important;
  }

  iframe {
    margin-top: -20px;
    margin-bottom: 2rem;
  }
  .spinner {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border: 4px solid rgb(253, 237, 71, 0.8);
    border-left-color: transparent;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 950px) {  
    .content {
      margin-top: -32px;
    }
    iframe {
      height: 400%;
    }
}
</style>