<template>
  <div class="content">
    <div class="paragraf">
        <h2>Política de Privacidad</h2>
        <p>En esta página puedes encontrar información sobre cómo se manejarán y protegerán tus datos personales. La política de privacidad expresa cómo tratará la información personal de todas las personas que interactúan con el sitio web. Te pedimos que leas cuidadosamente esta política de privacidad antes de utilizar la web, la cual está redactada según las últimas directrices europeas, de forma que no necesites de un abogado para comprenderlas sino que simplemente leyendo cualquier persona esta política pueda ser capaz de comprenderla totalmente.</p>
        <p>De acuerdo con el Reglamento UE 2016/679 y la Ley Orgánica 3/2018, te informa que al aceptar esta política de privacidad, prestas tu consentimiento expreso, informado, libre e inequívoco para que procese tus datos personales, los cuales serán protegidos con medidas técnicas y organizativas adecuadas a la normativa vigente.</p>
    </div>
    <div class="paragraf">
        <h2>Datos personales tratados</h2>
        <p>En este sitio web se pueden recoger los siguientes datos, siempre para poder ofrecer el servicio que la web tiene como finalidad, dependiendo de las acciones realizadas en el sitio web por el usuario:</p>
        <p>Datos de navegación: el sitio web puede conocer tu dirección IP, dispositivo utilizado, navegador con el que accede el usuario al sitio web y los datos de navegación y comportamiento del usuario dentro del sitio. Esto es para poder ofrecer la mejor experiencia de uso a los usuarios del sitio web.</p>
        <p>Datos identificativos y de contacto: nombre, apellidos, correo electrónico o cualquier otro método de contacto siempre que el usuario voluntariamente los indique.</p>
        <p>Datos contractuales: en caso de adquirir bienes o servicios ofrecidos en el sitio web.</p>
    </div>
    <div class="paragraf">
        <h2>Base legal del tratamiento de datos</h2>
        <p>Los datos de los usuarios son tratados en base a las siguientes bases legales:</p>
        <p>Interés legítimo del responsable del tratamiento de datos para proteger a los usuarios de cualquier tipo de fraude o abuso en el uso de los servicios.</p>
        <p>Consentimiento otorgado por el usuario relativo a contacto, suscripciones o comunicaciones comerciales.</p>
        <p>Ejecución de contratos con para contratar servicios y poder gestionar la entrega de los mismos.</p>   
    </div>
    <div class="paragraf">
        <h2>Tiempo de conservación de los datos personales</h2>
        <p>El tiempo de conservación de los datos personales de los usuarios será el menor posible en todo caso, aunque pudiendo mantenerse en más tiempo en caso de tener que cumplir con algún imperativo legal que obligue a dicho mantenimiento, como es el caso de las infracciones y sanciones en el orden social (4 años), las acciones civiles personales (5 años), a efectos contables (6 años) y para cumplir con algunas normativas relativas a la Prevención de Blanqueo de Capitales y Financiación de terrorismo (10 años). En cualquier caso, en términos generales y sin que concurra ninguna de las circunstancias que obliguen a un mantenimiento mayor de los datos, se tendrán el tiempo mínimo posible.</p>
    </div>
    <div class="paragraf">
        <h2>¿Qué derechos tienes respecto de tus datos?</h2>
        <p>Tus datos son tuyos, por lo que tendrás todo el derecho a conocer el tratamiento de datos que se les da por parte de a tus datos. En concreto, tendrás derecho a:</p>
        <ul>
            <li>Acceder a tus datos</li>
            <li>Rectificar o suprimir los datos</li>
            <li>Cancelación de los datos</li>
            <li>Limitación del tratamiento de los datos</li>
            <li>Oposición al tratamiento</li>
            <li>Portabilidad de los datos en cualquier momento</li>
        </ul>
        <p>Además, tienes derecho a retirar el consentimiento en cualquier momento acerca del tratamiento de tus datos, aunque hayas consentido el mismo para una finalidad concreta dentro de la web. En caso de que consideres que el tratamiento de tus datos vulnera tus derechos, puedes ponerte en contacto con la Agencia de Protección de Datos a través del siguiente enlace: <a href="https://www.aepd.es/">https://www.aepd.es/</a></p>
        <p>Si quieres ejercitar alguno de los derechos anteriormente descritos, puedes hacerlo en cualquier momento enviando un correo electrónico a la dirección it@tronando.com. Para ello necesitarás enviar el formulario de ejercicio de derechos sobre datos personales, que podrás solicitarnos vía email o descargar de la<a href="https://www.aepd.es/es"> propia web de la Agencia de Protección de Datos.</a> En cualquier caso, deberán ir firmados electrónicamente o adjuntando una fotocopia/imagen de tu DNI o documento identificativo correspondiente.</p>
        <p>El formulario podrá enviarse tanto por correo electrónico como vía correo ordinario a la dirección Edificio Lanzadera, La Marina de, Carrer del Moll de la Duana, s/n, 46024 Valencia, Valencia, Valencia.</p>
    </div>
    <div class="paragraf">
        <h2>Seguridad y declaración de brechas de seguridad</h2>
        <p>En www.tronando.com tomamos todas las medidas a nuestro alcance y adecuadas al nivel de riesgo de los datos manejados para proteger tu información personal. En caso de que detectemos alguna brecha de seguridad o creamos razonablemente que alguno de tus datos puede haber sido expuesto, nos pondremos en contacto de forma inmediata para que puedas tomar las medidas de protección necesarias frente a cualquier tipo de ataque dirigido contra tus datos personales.</p>
    </div>
    <div class="paragraf">
        <h2>Seguridad y mantenimiento de tus datos</h2>
        <p>
            En www.tronando.com nos comprometemos a utilizar y tratar tus datos personales con total confidencialidad, de manera acorde a la finalidad de los mismos, así como adaptar todas las medidas para evitar cualquier tipo de alteración o pérdida de los mismos, así como accesos no autorizados, en base a lo establecido por la normativa vigente en materia de protección de datos.
        </p>
        <p>Por otro lado, www.tronando.com debe ser claro y transparente con el hecho de que, al igual que cualquier otro sitio web de internet, no podemos garantizar la seguridad y el no acceso autorizado por parte de piratas informáticos o cualquier otro individuo u organización criminal, lo cual podría violar la confidencialidad de los datos custodiados.</p>
        <p>Respecto del manejo de datos por otro tipo de procesamiento, www.tronando.com se encargará siempre de que el procesamiento realizado por cualquier persona autorizada por estará bajo la misma obligación de confidencialidad que nosotros.</p>
        <p>En cualquier caso, si en algún momento hay algún error o fuga de información o datos personales, lo comunicará a la mayor brevedad posible a los usuarios afectados o potencialmente afectados por ello.</p>
    </div>
    <div class="paragraf">
        <h2>Exactitud y veracidad de los datos</h2>
        <p>Al ser quien los proporciona, el único responsable de la veracidad de los datos proporcionados a www.tronando.com eres tú como usuario, no teniendo ninguna responsabilidad respecto a la exactitud o veracidad de los datos. En cualquier caso, los usuarios deben proporcionar datos exactos y auténticos, manteniendo los mismos debidamente actualizados.</p>
        <p>El usuario se compromete a proporcionar información correcta y completa en base a esta política de privacidad siempre que remita datos de cualquier tipo a través de www.tronando.com.</p>
    </div>
    <div class="paragraf">
        <h2>Revocabilidad de los datos</h2>
        <p>El usuario presta su consentimiento para el tratamiento y cesión de los datos en los términos expuestos, pero este no es un derecho absoluto, dado que puede ser revocado por el titular de los datos en cualquier momento, con la mera manifestación de su voluntad y llevando a cabo el procedimiento descrito anteriormente para el ejercicio de los derechos arco ante . En cualquier caso, esta revocación no tendrá carácter retroactivo en ningún caso.</p>
    </div>
    <div class="paragraf">
        <h2>Cambios en la política de privacidad</h2>
        <p>tiene reservado el derecho a modificar la presente política de privacidad para adaptarla a las novedades legislativas que existan en el futuro, así como a las propias prácticas comunes de la industria o particulares de . En caso de actualización de las políticas, se anunciarán en esta página cualesquiera cambios con anterioridad a la aplicación y obligatoriedad de los mismos.</p>
    </div>
    <div class="paragraf">
        <div class="copy">
    <div>Copyright &copy; {{new Date().getFullYear()}} - Tronando</div>
</div>
    </div>
  </div>
</template>

<script>
export default {
    setup() {window.scrollTo(0, 0); document.title = 'Tronando | Política de Privacidad 🔐'
}
}
</script>

<style lang="scss" scoped>
.content {
    margin: 120px auto;
    width: 50%;
    text-align: justify;
    h2 {
        margin-bottom: 1rem;
        font-weight: 550;
        
    }
    .paragraf {
        margin-top: 3.5rem;
    }
    li {
        margin-left: 40px;
        margin-top: 15px
    }
    @media (max-width: 950px) {
        width: 90%;
        text-align: left;
    }
}
</style>