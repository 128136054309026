export const logoUsers = [
	new URL("@/assets/subscribers-work/sublogo1.png", import.meta.url),
    new URL("@/assets/subscribers-work/sublogo2.png", import.meta.url),
    new URL("@/assets/subscribers-work/sublogo3.png", import.meta.url),
    new URL("@/assets/subscribers-work/sublogo4.png", import.meta.url),
    new URL("@/assets/subscribers-work/sublogo5.png", import.meta.url),
    new URL("@/assets/subscribers-work/sublogo6.png", import.meta.url),
    new URL("@/assets/subscribers-work/sublogo7.png", import.meta.url),
    new URL("@/assets/subscribers-work/sublogo8.png", import.meta.url),
    new URL("@/assets/subscribers-work/sublogo9.png", import.meta.url),
    new URL("@/assets/subscribers-work/sublogo10.png", import.meta.url),
    new URL("@/assets/subscribers-work/sublogo11.png", import.meta.url)
];

export const logoWork = [
	new URL("@/assets/sponsors-work/worklogo1.png", import.meta.url),
    new URL("@/assets/sponsors-work/worklogo2.png", import.meta.url),
	//new URL("@/assets/sponsors-work/worklogo3.png", import.meta.url),
    new URL("@/assets/sponsors-work/worklogo4.png", import.meta.url),
	new URL("@/assets/sponsors-work/worklogo5.png", import.meta.url),
    new URL("@/assets/sponsors-work/worklogo6.png", import.meta.url),
    new URL("@/assets/sponsors-work/worklogo7.png", import.meta.url),
	new URL("@/assets/sponsors-work/worklogo8.png", import.meta.url)



];


