<template>
  <div class="form">
    <form @submit.prevent="subscribeUser">
      <input
        :disabled="isLoading"
        v-model="userEmail"
        type="email"
        required
        class="form__email"
        placeholder="Email ✉"
      />
      <button :disabled="isLoading" type="submit" class="form__button">
        <span>Suscríbete</span>
      </button>
    </form>
    <div v-show="isLoading" class="loadercontainer">
      <div class="dot-pulse"></div>
    </div>
  </div>
  <CookieBanner @hideBanner="showBanner = false" v-if="showBanner" />
</template>

<script>
import { postSuscription, getSuscription } from "@/utils/apiCalls";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import CookieBanner from "@/components/core/CookieBanner.vue";
export default {
  data() {
    return {
      userEmail: "",
      showBanner: false,
      isLoading: false,
    };
  },
  components: {
    CookieBanner,
  },
  methods: {
    async subscribeUser() {
      this.isLoading = true;
      try {
        await postSuscription(this.userEmail).then(async (response) => {
          const isvalidation = await getSuscription(response.data.data.id);
          this.checkStatus(isvalidation.data.data.status);
        });
      } catch (error) {
        this.checkStatus("error");
      }
      this.userEmail = "";
      this.isLoading = false;
    },
    checkStatus(status) {
      const $toast = useToast();
      switch (status) {
        case "validating":
        case "pending":
        case "needs_attention":
          $toast.open({
            message: `<b> ¡Aún no estás suscrito! </b><p> Por favor revisa tu correo para confirmar la suscripción ⚡.</p> `,
            type: "warning",
            duration: 8000,
            position: "top-right",
          });
          break;
        case "active":
          $toast.open({
            message: `<b> ¡Gracias por formar parte de Tronando! </b> <p>Esta dirección de correo electrónico ya está suscrita.</p> `,
            type: "success",
            duration: 8000,
            position: "top-right",
          });
          break;
        case "invalid":
          $toast.open({
            message: `<b> Error al suscribirse </b> <p>Por favor, asegúrate de que el correo introducido es válido.</p> `,
            type: "error",
            duration: 8000,
            position: "top-right",
          });
          break;
        case "error":
          $toast.open({
            message: `<b> Se ha producido un error </b> <p>Por favor, inténtalo más tarde.</p> `,
            type: "error",
            duration: 8000,
            position: "top-right",
          });
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  margin-bottom: 25px;
  filter: drop-shadow(2px 2px 3px rgb(0, 0, 0, 0.2));
}

.form__email {
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #cad3db;
  width: 20rem;
  height: 3.5rem;
  font-size: 18px;
  color: var(--text-color);
}

.form__email:focus {
  outline: 0px;
}

.form__button {
  background: var(--theme-on-primary);
  padding: 0px;
  border: none;
  border-radius: 0px 5px 5px 0px;
  width: 10rem;
  margin-left: -5px;
  height: 3.5rem;
  font-size: 18px;
  border-top: 1px solid #cad3db;
  border-bottom: 1px solid #cad3db;
  border-right: 1px solid #cad3db;
  color: var(--text-color);
  letter-spacing: 0.1rem;
}

.form__button:hover {
  background: var(--theme-surface-1);
  span {
    color: var(--text-color);
    font-weight: 600;
  }
}

//LOADING ANIMATIONS
.loadercontainer {
  display: flex;
  justify-content: center;
  margin-top: 2.3rem;
}
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--theme-on-primary);
  color: var(--theme-on-primary);
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--theme-on-primary);
  color: var(--theme-on-primary);
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

@media (max-width: 750px) {
  .form__email {
    width: 95vw;
  }
  .form__button {
    width: 95vw;
    margin-left: 0px;
    margin: 5px;
  }

  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
</style>