<template>
    <div class="home">
        <HomeHeroSection  />
        <Suspense>
          <PostList class="bg-light"/>
        </Suspense>
    
       
        <Testimonial/>
        <!-- <FAQSection/> -->
      
       
    </div>
  </template>
  
<script>
import HomeHeroSection from '@/components/sections/HomeHeroSection.vue';
import PostList from '@/components/posts/PostsList.vue';
import Testimonial from '@/components/testimolial/Testimonial.vue';
import FAQSection from '@/components/sections/FAQSection.vue';

export default {
    name: "Home",
    data() {
      return {
        postList: []
      }
    },
    components: 
    {
      HomeHeroSection,
      Testimonial,
      PostList,
      FAQSection
    },
    methods: {
      getRouterName() {
        if (this.$route.name != 'home')
          this.$router.push('/')
          
      }
    },
    updated() {
      this.getRouterName();
      document.title = 'Tronando | ¡Ponte al día del mundo con un toque de humor! 🤘🧠'

    },
    mounted() {
      this.getRouterName();
      window.scrollTo(0, 0)
      
    },
    setup() {
      document.title = 'Tronando | ¡Ponte al día del mundo con un toque de humor! 🤘🧠'
    }
};
  </script>

  <style scoped>
  .home {
    margin-top: 120px;
  }
  @media (max-width: 950px) {
    .home {
    margin-top: 70px;
    margin-bottom:3rem;
  }
}
  </style>
  
