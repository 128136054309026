<template>

<div class="content">
 

<h2>{{ text }}</h2>

  <div class="logos">
      <div class="logos-slide left">
        <img v-for="(item, key) in logos" :key="key" :src="item"/>
      </div>

      <div class="logos-slide left">
        <img v-for="(item, key) in logos" :key="key" :src="item"/>
      </div>
      
</div>


</div> 
</template>

<script>
export default {
  props: {
    logos: Array,
    text: String
  }
}
</script>

<style scoped lang="scss">

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.content {
    width: 80%;
    padding: 20px;
    
    
  margin: 1rem auto;
  
  h2 {
    font-size: 32px;
	margin-bottom: 40px;
  text-align: center;
  }
 
}

.logos {
   
  overflow: hidden;
  padding: 60px 0;

  white-space: nowrap;
 
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  
}
.left {
  animation: 35s slide infinite linear;
}


.logos-slide img {
  height: 50px;
  margin: 0 40px;
}

@media (max-width: 650px) { 
  .content {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    h2 {
      font-size: 23px;
    margin-top: 5px;
    margin:20px;

    }
  }
    .logos:before, .logos:after  {
  display: none;
}
.logos-slide img {
  height: 50px;
  margin: 0 20px;

}


}
</style>