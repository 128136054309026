<template>

    <div class="blog-card" :class="{'alt' : isAlt}" v-on:click="openPosts(post.id)">
    <div class="meta">
      <img v-if="this.image != null" class="photo" :src="image">
      <img v-if="this.image == null" class="photo" src="@/assets/lanzadera_3.jpeg">

      <ul class="details">
        <li class="author">Belen Baixauli</li>
        <li class="tags">
          <ul>
            <li>{{ imageAuthor }} ⚡</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="description">
      <h1>{{ post.title }}</h1>
      <p class="date"><i class="fa-solid fa-calendar-days"></i> {{ convertEpochTime(post.publish_date)}}</p>
      <p class="read-more">
        <a v-on:click="openPosts(post.id)"> Seguir leyendo</a>
      </p>
    </div>
   </div>

</template>

<script>
import {getImagesByName} from '@/utils/apiCalls'


export default {
    data() {
        return {
            image: null,
            imageAuthor: 'Tronando'
        };
    },
    props: {
        post: Object,
        isAlt: Boolean
    },
    methods: {
        convertEpochTime(time) {
            const d = new Date(time * 1000);
            return d.toLocaleDateString("es-ES");
        },
        openPosts(id) {
            this.$router.push({ name: "post", params: { id: id} });
        },
        async getImage() {
          if (this.post.thumbnail_url && this.post.thumbnail_url != this.old_thumbnail) {
              this.image = this.post.thumbnail_url
          } else {
                try {
                const tagList = ['Space', 'Nature', 'Economy Graph', 'Wall Street', 'stock market', 'Technology']
                const img = await getImagesByName(tagList[Math.floor(Math.random()*tagList.length)])
                this.image = img.data[0].src.landscape
                this.imageAuthor = 'Pexels'
                  } catch (imgError) {
                    console.log(imgError)
                  }
          }
          

         
        }
    },
    async created() {
      await this.getImage()
      
    }
}
</script>

<style lang="scss" scoped>

$color_white: #fff;
$color_prime: var(--theme-on-primary);
$color_grey: #e2e2e2;
$color_grey_dark: #a2a2a2;

.blog-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 1px 4px 7px 1px rgba(#000, .1);
  margin-bottom: 2%;
  background: $color_white;
  line-height: 1.7;
  border-radius: 5px;
 
  overflow: hidden;
  z-index: 0;
  a {
    color: inherit;
    &:hover {
      color: $color_prime;
    }
  }
  &:hover {
    .photo {
      transform: scale(1.3) rotate(3deg);
    }
  }
  .meta {
    position: relative;
    z-index: 0;
    height: 200px;
  }
  .photo {
    position: absolute;
    width: 100%;
    height: 150%;
  
    transition: transform .2s;
  }
  .details,
  .details ul {
    margin: auto;
    padding: 0;
    list-style: none;
  }

  .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    margin: auto;
    transition: left .2s;
    background: rgba(#000, .6);
    color: $color_white;
    padding: 10px;
    width: 100%;
    font-size: .9rem;
    a {
      text-decoration: dotted underline
    }
    ul li {
      display: inline-block;
    }
    .author:before {
      font-family: FontAwesome;
      margin-right: 10px;
      content: "\f007";
    }

    .tags {
      ul:before {
        font-family: FontAwesome;
        content: "\f030";
        margin-right: 10px;
      }
      li {
        margin-right: 2px;
        &:first-child {
          margin-left: -4px;
        }
      }
    }
  }
  .description {
    padding: 1rem;
    background: $color_white;
    position: relative;
    z-index: 1;
    h1 {
      line-height: 1;
      
      font-size: 1.4rem;
    }
    h2 {
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;
      color: $color_grey_dark;
      margin-top: 5px;
    }
    .read-more {
      cursor: pointer;
      text-align: right;
      a {
        color: var(--text-color);
        display: inline-block;
        position: relative;
      
        &:after {
          content: "\f061";
          font-family: FontAwesome;
          margin-left: -10px;
          opacity: 0;
          vertical-align: middle;
          transition: margin .3s, opacity .3s;
        }

        &:hover:after {
          margin-left: 5px;
          opacity: 1;
        }
      }
    }
  }
  p {
    position: relative;
    margin: 1rem 0 0;
    &:first-of-type {
      margin-top: 1.25rem;
      &:before {
        content: "";
        position: absolute;
        height: 5px;
        background: $color_prime;
        width: 35px;
        top: -0.75rem;
        border-radius: 3px;
      }
    }
  }
  &:hover {
    .details {
      left: 0%;
    }
  }



  @media (max-width: 950px) {
    
    .read-more {
      a {
        background-color: var(--theme-on-primary);
        padding: 10px;
        border-radius: 5px;
      }
      
    }
   
@media (max-width: 640px) {
  margin: 1rem;
}
 
}

  @media (min-width: 640px) {
    flex-direction: row;
    max-width: 700px;
  
    .meta {
      flex-basis: 40%;
      height: auto;
    }
      
    .description {
      flex-basis: 60%;
      &:before {
        transform: skewX(-3deg);
        content: "";
        background: #fff;
        width: 30px;
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
    }
    &.alt {
      flex-direction: row-reverse;
      .description {
        &:before {
          left: inherit;
          right: -10px;
          transform: skew(3deg)
        }
      }
      .details {
        padding-left: 25px;
      }
    }
  }
}
</style>

