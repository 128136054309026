<template>
  <div class="main">
        <div class="aboutus-menu">
          <a class="aboutus-menu-link" id="servicesOption" :class="{active: ServiceisActive}" @click="switchMenu($event)">Servicios</a>
          <a class="aboutus-menu-link" id="teamOption" :class="{active: TeamisActive}" @click="switchMenu($event)" >Equipo</a>
        </div>
      <AboutTeamVue class="fadeInDown" v-show="TeamisActive"/>
      <AboutServicesVue class="fadeInDown" v-show="ServiceisActive"/>

  </div>
</template>

<script>

import AboutTeamVue from '../components/aboutus/AboutTeam.vue';
import AboutServicesVue from '../components/aboutus/AboutServices.vue';
export default{
  data() {
    return {
      mobile: null,
      ServiceisActive: true,
      TeamisActive: false

    }
  },
  components: {
    AboutTeamVue, AboutServicesVue
  },
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1000) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      return;
    },
    switchMenu(event) {
      switch (event.target.id) {
        case 'servicesOption':
          this.ServiceisActive = true
          this.TeamisActive = false
        break;
        case 'teamOption':
          this.ServiceisActive = false
          this.TeamisActive = true
        break;

        default:
          break;
      }

    }
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
  
    this.checkScreen()
  },
  setup() {
    document.title = 'Tronando | Sobre nosotros 🤝'
    window.scrollTo(0, 0)
    
  }
}
</script>

<style lang="scss" scoped>

$bg-color: var(--theme-on-primary);
$border-color: #272a3a;
$side-title: #5c5e6e;
$button: #8f98a9;

.main {
  margin-top: 0px;
  margin-bottom:5rem;
}
.aboutus-menu {
  background-color: white;
  box-shadow: 0 2px 3px -3px black;
  padding-top: 1rem;
  letter-spacing: .1rem;
  font-weight: 600;
  text-transform: uppercase;
 position: relative;
 z-index: 2;
 bottom: 0;
 width: 100%;
 display: flex;
 justify-content: space-evenly;
 border-radius: 0 0 4px 4px;
}

.aboutus-menu-link {
  padding: 2.3rem 1.5rem 2rem 1.5rem;
 margin-bottom:15px;
 color: black;
 transition: 0.3s;
 cursor: pointer;
background: linear-gradient(90deg, rgba(246,225,2,1) 0%, rgba(253,237,71,1) 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 88%;
  transition: background-size 0.25s ease-in;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
 &.active {
  background-size: 100% 88%;
 }

}

@media (max-width: 1200px) {  

  .aboutus-menu-link {
    
    padding: 2rem 1.5rem 2rem 1.5rem;
  }
}


//ANIMATIONS 
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInDown {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  }
  100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  }
  }
  @keyframes fadeInDown {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(0, -40%, 0);
  transform: translate3d(0, -40%, 0);
  }
  100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  }
  } 
</style>