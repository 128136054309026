<template>
  <div class="content">
    <div class="bg-light">
  <div class="container py-5" v-show="!mobile">
    <div class="row h-100 align-items-center py-5">
      <div class="col-lg-12">
        <h1 class="display-4 text-center mt-2" style="letter-spacing: 1rem; text-transform: uppercase;">Sobre nosotros</h1>
        <p class="lead text-muted text-center mb-0 mt-30" style="margin-top: 20px;">Somos un equipo joven pero bien preparado, apasionado por la actualidad y que trabaja duro para filtrar el ruido y ofrecerte la información clave que moldea el mundo.</p>
       
      </div>
    </div>
  </div>
  <div class="container py-5" v-show="mobile">
    <div class="row h-100 align-items-center py-5">
      <div class="col-lg-12" >
        <h1 class="display-4 text-center mt-2">Sobre nosotros</h1>
        <p class="lead text-muted text-justify mb-0" style="width: 95%; margin: 0 auto; margin-top: 30px;" >Somos un equipo joven pero bien preparado, apasionado por la actualidad y que trabaja duro para filtrar el ruido y ofrecerte la información clave que moldea el mundo.</p>
      </div>
    </div>
  </div>
</div>
<div class="bg-white py-5">
    <div class="container py-5">
    <div class="row align-items-center mb-5" v-show="!mobile">
      <div class="col-lg-6 order-2 order-lg-1"><i class="fa fa-bar-chart fa-2x mb-3"></i>
        <h2 class="font-weight-light">40H DE NOTICIAS EN 5MIN</h2>
        <p class=" text-muted mb-4">En <b>TRONANDO</b> recopilamos los mejores datos durante la semana para resumirlos y llevarlos a tu bandeja de entrada <b>CADA LUNES</b>.</p>
        <p class=" text-muted mb-4">Lo único que tienes que hacer es leerlo en <u>5 minutos</u> desde tu email y presumir de ser el más inteligente de la ofi.</p>
      </div>
      <div class="col-lg-5 px-5 mx-auto order-1 order-lg-2 "><img src="@/assets/aboutus2/aboutus1.png" alt="" class="img-fluid mb-4 mb-lg-0 imgCEO"></div>
    </div>
    <div class="row align-items-center mb-5 " v-show="mobile">
      <img src="@/assets/aboutus2/aboutus1.png" alt="" class="border mb-4 mb-lg-0 mobileImage">
      <div class="col-lg-6 order-2 order-lg-2">
        <h2 class="font-weight-light border-bottom mt-3 mb-3">40H DE NOTICIAS EN 5MIN</h2> 
        <p class=" text-muted mb-4">En <b>TRONANDO</b> recopilamos los mejores datos durante la semana para resumirlos y llevarlos a tu bandeja de entrada <b>CADA LUNES</b>.</p>
        <p class=" text-muted mb-4">Lo único que tienes que hacer es leerlo en <u>5 minutos</u> desde tu email y presumir de ser el más inteligente de la ofi.</p>
      </div>
    </div>
    <div class="row align-items-center" v-show="!mobile">
      <div class="col-lg-5 px-5 mx-auto"><img src="@/assets/logotipo_tronando_amarillo.png" alt="" class="img-fluid mb-4 mb-lg-0"></div>
      <div class="col-lg-6"><i class="fa fa-leaf fa-2x mb-3"></i>
        <h2 class="font-weight-light ">Entérate de lo que sucede</h2>
        <p class=" text-muted mb-4">
          No queremos que simplemente vivas tu vida sino que lo hagas a tu full potential. 
        </p>
        <p class=" text-muted mb-4">Entender términos básicos de finanzas como los tipos de interés, si el banco central va a imprimir mas dinero, o si China reabre su economía, ¡TE AFECTA! </p>
        <p class="text-muted mb-4">
          Nosotros te contamos cómo y por qué.
          </p>
       
        
      </div>
    </div>
    <div class="row align-items-center" v-show="mobile">
      <div class="col-lg-12">
        <h2 class="font-weight-light border-bottom mt-0 mb-3">Entérate de lo que sucede</h2>
        <p class="text-muted mb-4">
          No queremos que simplemente vivas tu vida sino que lo hagas a tu full potential. 
        </p>
        <p class="text-muted mb-4">Entender términos básicos de finanzas como los tipos de interés, si el banco central va a imprimir mas dinero, o si China reabre su economía, ¡TE AFECTA! </p>
        <p class="text-muted mb-4">
          Nosotros te contamos cómo y por qué.
          </p>
      </div>
    </div>
  </div>


  <div class="container py-5">

    <div class="row align-items-center mb-5" v-show="!mobile">
      <div class="col-lg-6 order-2 order-lg-1"><i class="fa fa-bar-chart fa-2x mb-3"></i>
        <h2 class="font-weight-light">Belén fundó Tronando en 2022</h2>
        <p class="text-muted mb-4">
Es de La Terreta (Valencia), pero en la carrera dio el salto a Nueva York y le gustó tanto que se quedó 5 años.</p>
        <p class=" text-muted mb-4">Ha pasado por entidades como: <b>JPMorgan,Citibank y Christian Dior.</b> Además, escribía artículos de inversión para Short Squeez.</p>
        <p class="text-muted mb-4">Así se dio cuenta de que era buena comunicadora y de que no había nada igual en España, por lo que en sus vacas de verano decidió empezar Tronando.</p>
        <p class="text-muted mb-4">¡Y desde entonces <b>TRUENA</b> ⚡ cada lunes! 
</p>
      </div>
      <div class="col-lg-5 px-5 mx-auto order-1 order-lg-2 "><img src="@/assets/aboutus/ceo_tronando.jpeg" alt="" class="img-fluid mb-4 mb-lg-0 imgCEO"></div>
    </div>
    <div class="row align-items-center mb-5" v-show="mobile">
      <img src="@/assets/aboutus/ceo_tronando.jpeg" alt="" class="border mb-4 mb-lg-0 mobileImage">
      <div class="col-lg-6 order-2 order-lg-2">
        <h2 class="font-weight-light">Belén fundó Tronando en 2022</h2>
        <p class="text-muted mb-4">
Es de La Terreta (Valencia), pero en la carrera dio el salto a Nueva York y le gustó tanto que se quedó 5 años.</p>
        <p class=" text-muted mb-4">Ha pasado por entidades como: <b>JPMorgan,Citibank y Christian Dior.</b> Además, escribía artículos de inversión para Short Squeez.</p>
        <p class="text-muted mb-4">Así se dio cuenta de que era buena comunicadora y de que no había nada igual en España, por lo que en sus vacas de verano decidió empezar Tronando.</p>
        <p class="text-muted mb-4">¡Y desde entonces <b>TRUENA</b> ⚡ cada lunes! 
</p>
      </div>
      </div>
    
    

  </div>
</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mobile: false
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1200) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      return;
    },
  },
};

</script>

<style lang="scss" scoped>
i {
  color: var(--theme-on-primary);
}
.content {
margin-bottom: 5px;
margin-top: -50px;
font-size: 1rem;
}


.imgCEO {
  width: 30rem;
  height: 24rem;
  border-radius: 20px;
  box-shadow: 2px 7px 14px -5px rgba(0,0,0,0.8);
}


.mobileImage {
  width: 13rem;
  height: 13rem;
  border-radius: 50%;
  text-align: center; 
  margin: -40px auto;

}
.img-shape {
  border-radius: 10px;
}
</style>

