<template>
  <div class="subscribe">
    <img src="@/assets/logo-tronando-empty-2.png" />
    <h2 class="subscribe__title">Tronando</h2>
    <p class="subscribe__copy">{{ homeHeroMessage }}</p>
    <SubscribeForm />
  </div>
</template>

<script>
import { heroSection } from "@/config/global";
import SubscribeForm from "@/components/forms/SubscribeForm.vue";
export default {
  data() {
    return {
      homeHeroMessage: heroSection,
    };
  },
  components: { SubscribeForm },
};
</script>


<style scoped>
.subscribe {
  padding: 0px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

p {
  font-size: 20px;
}

img {
  width: 100px;
  height: 100px;
  margin-bottom: 15px;
  border-radius: 10px;
  filter: drop-shadow(2px 2px 2px rgb(0, 0, 0, 0.2));
}
.subscribe__title {
  font-weight: bold;
  font-size: 39px;
  margin-bottom: 18px;
}

.subscribe__copy {
  max-width: 80%;
  text-align: center;
  margin-bottom: 53px;
  line-height: 1.6;
}

@media (max-width: 950px) {
  .subscribe {
    padding: 10px;
    margin: 10px;
  }
  img {
    margin-top: 3rem;
  }
}
</style>
