<template >
    <div class="content">
        <div class="spinner-container" v-show="isLoading">
          <div class='spinner '></div>
        </div>
        <div v-html="webHTML" class="post"></div> 
    </div>
    
</template>

<script>
import {fetchPost} from '@/utils/apiCalls'
export default {
    data() {
        return {
            postId : this.$route.params.id,
            webHTML : null,
            isLoading : true,
            tittle: ''
        }
    },
    
    methods: {
        async getPost() {
            this.isLoading = true
            
            await fetchPost(this.postId).then(res => {
              this.webHTML = res.data.data.content.premium.web
              this.tittle = res.data.data.subject_line
              this.isLoading = false
           
            })
            
           
           
        }
    },
    async created() {
        window.scrollTo(0, 0)
        await this.getPost()
        document.title = this.tittle
    }
}
</script>

<style scoped lang="scss">
 .post {
 
  margin-top:100px;
 

}

.content::v-deep {
  * {
    font-family: 'Helvetica', 'Arial', sans-serif;
    body {
    font: 9pt/1.5em sans-serif;
}
pre, code, tt {
    font: 1em/1.5em 'Andale Mono', 'Lucida Console', monospace;
}
h1, h2, h3, h4, h5, h6, b, strong {
    font-weight: bold;
}
em, i, dfn {
    font-style: italic;
}
dfn {
    font-weight:bold;
}
p, code, pre, kbd {
    margin:0 0 1.5em 0;
}
blockquote {
    margin:0 1.5em 1.5em 1.5em;
}
cite {
    font-style: italic;
}
li ul, li ol {
    margin:0 1.5em;
}
ul, ol {
    margin:0 1.5em 1.5em 1.5em;
}
ul {
    list-style-type:disc;
}
ol {
    list-style-type:decimal;
}
ol ol {
    list-style: upper-alpha;
}
ol ol ol {
    list-style: lower-roman;
}
ol ol ol ol {
    list-style: lower-alpha;
}
dl {
    margin:0 0 1.5em 0;
}
dl dt {
    font-weight:bold;
}
dd {
    margin-left:1.5em;
}
table {
    margin-bottom:1.4em;
    width:100%;
}
th {
    font-weight:bold;
}
th, td, caption {
    padding:4px 10px 4px 5px;
}
tfoot {
    font-style:italic;
}
sup, sub {
    line-height:0;
}
abbr, acronym {
    border-bottom: 1px dotted;
}
address {
    margin:0 0 1.5em;
    font-style:italic;
}
del {
    text-decoration: line-through;
}
pre {
    margin:1.5em 0;
    white-space:pre;
}
img.centered, .aligncenter, div.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
img.alignright {
    display: inline;
}
img.alignleft {
    display: inline;
}
.alignright {
    float: right;
    margin-left: 10px;
}
.alignleft {
    float: left;
    margin-right: 10px;
}
img {
    max-width: 100%;
}
* html .clearfix {
    height: 1%;
}
* + html .clearfix {
    display: inline-block;
}
.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
* html .group {
    height: 1%;
}
* + html .group {
    display: inline-block;
}
.group:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
  }
  img {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
   
  }
  .image-wrapper::after {
    display: none;
  }
  .flex {
    display: flex;
  }
  .justify-between {
    display: flex;
    justify-content: space-between;
  }
}

.spinner-container {
  height: 100vh;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;

  .spinner {
    margin: 30px auto 100px auto;
    border: 4px solid rgb(253, 237, 71, 0.8);
    border-left-color: transparent;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    
  }
}
 
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
</style>