<template>
  <div class="popup" id="mainPopup" @click="ClosePopup($event)">
    <div class="popup-inner">
      
      <form class="form-style-9" @submit.prevent="sendEmail">
        
       
        <h2>Solicitar patrocinio</h2>
        <ul>
          <li>
            <input
              type="text"
              name="field1"
              class="field-style field-full align-left"
              placeholder="Compañía"
              v-model="company"
              required
            />
    
          </li>
          <li>
            <input
              type="email"
              name="field3"
              class="field-style field-full align-none"
              placeholder="Correo de contacto"
              v-model="email"
              required
            />
           
          </li>
          <li>
            <input
              type="url"
              name="field3"
              class="field-style field-full align-none"
              placeholder="URL de tu web"
              v-model="url"
              required
            />
          </li>
       
          <li>
            <textarea
              name="field5"
              class="field-style"
              placeholder="Breve descripción de la actividad de la empresa"
              v-model="desc"
              required
            ></textarea>
          </li>
          <li>
            <input type="submit" value="Solicitar" />
            <button id="closePopup" v-on:click="TogglePopup()"> Cancelar </button>
          </li>
        </ul>
      </form>
    </div>
  </div>
</template>

<script>
import { sendSponsorMail } from "@/utils/apiCalls";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
export default {
  data() {
    return {
      company: "",
      sector: "",
      url: "",
      email: "",
      desc: "",
    };
  },
  props: ["TogglePopup"],
  methods: {
    sendEmail() {
      try {
        const mailInfo = {
          company: this.company,
          sector: this.sector,
          url: this.url,
          email: this.email,
          desc: this.desc,
        };
        sendSponsorMail(mailInfo);
        this.toggleModal("success");
      } catch (error) {
        this.toggleModal("error");
      }
      this.TogglePopup();
    },
    toggleModal(status) {
      const $toast = useToast();
      switch (status) {
        case "success":
          $toast.open({
            message: `<b> ¡Gracias por querer ser parte de Tronando⚡! </b><br> <p>Revisamos tu petición y nos ponemos en contacto contigo.<br>Y hasta entonces, Rock & Roll🤘🧠!</p>`,
            type: "success",
            duration: 10000,
            position: "top-right",
          });
          break;
        case "error":
          $toast.open({
            message: `<b> Error </b><br> <p>Ha ocurrido un error. Por favor, inténtelo más tarde</p>`,
            type: "error",
            duration: 5000,
            position: "top-right",
          });
          break;
      }
    },
    ClosePopup(event) {
      switch (event.target.id) {
        case 'mainPopup':
          this.TogglePopup()
          break;
      }
    
      
      
    }
  },
};
</script>

<style lang="scss" scoped>
.form-style-9 {
  max-width: 1250px;
  width: 500px;
  background: #fcfcfc;
  padding: 30px;
  h2 {
    letter-spacing: 3px;
    text-align: center;
    margin-bottom: 30px;
  }
  box-shadow: 1px 1px 25px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  border: 2px solid var(--theme-on-secondary);
}
.form-style-9 ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
input, textarea {
  margin-bottom: 15px;
}

.form-style-9 ul li {
  display: block;

  min-height: 35px;
}
.form-style-9 ul li .field-style {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 8px;
  outline: none;
  border: 1px solid rgb(170, 170, 170);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}
.form-style-9 ul li .field-style:focus {
  box-shadow: 0 0 5px var(--theme-on-primary);
  border: 1px solid var(--theme-on-primary);
}
.form-style-9 ul li .field-split {
  width: 49%;
}
.form-style-9 ul li .field-full {
  width: 100%;
}
.form-style-9 ul li input.align-left {
  float: left;
}
.form-style-9 ul li input.align-right {
  float: right;
}
.form-style-9 ul li textarea {
  width: 100%;
  height: 100px;
}
.img {
  img {
    height: 120px;
    width: 120px;
  }
  position: fixed;
  top: 0;
  left: 31rem;
  right: 0;
  bottom: 30rem;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-style-9 ul li input[type="button"],
.form-style-9 ul li input[type="submit"], button {
  background-color: var(--theme-on-secondary);

  display: inline-block;
  cursor: pointer;

  padding: 8px 18px;
  text-decoration: none;
  border-radius: 5px;
  margin: auto;
  width: 100%;
  border: 1px solid rgb(170, 170, 170);
  background-color: white;
  color: black;
}

button {
  margin-top: 10px;
  
  border: 1px solid rgb(170, 170, 170);
  background-color: white;
  color: black;
}

button:hover {
  border: 1px solid var(--theme-on-primary);
  color: var(--theme-on-primary);

}

.form-style-9 ul li input[type="button"]:hover,
.form-style-9 ul li input[type="submit"]:hover {
  background: linear-gradient(to bottom, var(--theme-on-secondary) 5%, var(--theme-on-primary) 100%);

}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.3);
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;

  .close:hover {
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }

  @media (max-width: 1500px) {
    .form-style-9 { 
      width: 100%;

    }
    .img { 
      display: none;
    }

    .popup-inner {
      margin: 15px;
      input[type=submit] {
        background-color: var(--theme-on-primary);
        border: 1px var(--text-color) solid;
        color: var(--text-color);
      }
      #closePopup {
        color: var(--theme-on-primary);
        border: 1px var(--theme-on-primary) solid;
      }
    }
  }

}
</style>
