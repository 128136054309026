<template>
  <div class="content">
    <div class="section1 bg-light">
      <div class="sectioncontent">
        <h1>​¿Te gusta nuestra forma de <span class="underline">comunicar?</span></h1>
      <p>​Eso nos empezaron a decir asociaciones, universidades, cámaras de comercio… Por lo que empezamos a ayudarles y desarrollar la vertical de negocio de <b>Crear + Gestionar Newsletters.</b> </p>
      <div class="grid">
        <div class="grid__item">
          <div class="card">
            <div class="card__content">
              <h2 class="card__header"><b>​Transformación de Tasa de Apertura</b></h2>
              <p class="card__text">​​Hemos demostrado un historial sólido de aumentar la tasa de apertura en las newsletters de nuestros partners. Imagina llegar de manera efectiva y que tus stakeholders estén ansiosos por abrir tus correos electrónicos. Nos ha pasado. </p>
            </div>
        </div>
        </div>
        <div class="grid__item">
          <div class="card">
            <div class="card__content">
              <h2 class="card__header"><b>Formato Atractivo y Ameno</b></h2>
              <p class="card__text">​Lo bueno si es breve, dos veces bueno. Nuestro enfoque innovador hace que los alumnos/ clientes/ comunidad empresarial no solo lean tus mensajes, sino que los disfruten.</p>
            </div>
        </div>
        </div>
        <div class="grid__item">
          <div class="card">
            <div class="card__content">
              <h2 class="card__header"><b>​Experiencia Personal</b></h2>
              <p class="card__text">Habiendo trabajado en una newsletter de EE. UU. con más de 250.000 suscriptores, crear nuestra propia newsletter en España y crecerla a más de 11.000 suscriptores, sabemos lo que funciona y lo que no. </p>
            </div>
        </div>
        </div>
      </div>
      <router-link to="/contactus">
        <div class="button">
        ​   ¿Te interesa?
        </div>
      </router-link>
      
      </div>
      
    </div>
    <div class="section2">
      <div class="sectioncontent2">
        <h1>Tronando</h1>
        <h4>​Eficiencia informativa cada lunes con nuestra propia newsletter, Tronando.</h4>
        <h5>​Suscríbete si no lo has hecho ya. </h5>
        <router-link to="/suscribe/1"> 
        <div class="button">
          ​Suscríbete gratis
        </div>
      </router-link>
      </div>
    </div>
    

  </div>

</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.underline {

  
  border-radius: 0.8em 0 1em 0.2em;
  text-shadow: 1px 1px 1px #fff;
  background-image: 
    linear-gradient(-100deg, 
      rgba(255,250,150,0.15),
      var(--theme-on-primary) 100%, 
      rgba(255,250,150,0.25)
    );
}
.button {
	display: flex;
	align-items: center;
	justify-content: center;
	
	cursor: pointer;

	svg {
		height: 55px;
		width: 150px;
		fill: none;
		stroke-width: 5;

		.o1 {
			stroke: rgba(var(--theme-on-primary), 0.7);
			fill: var(--theme-on-primary);
			transition: all 1s ease-in-out;
		}

		.o2 {
			stroke: rgb(0, 0, 0);
     
			stroke-dasharray: 20 420;
			stroke-dashoffset: 20;
			transition: all 1s ease-in-out;
		}
	}

	span {
		position: absolute;
		margin: auto 0;
		text-transform: uppercase;
		letter-spacing: 3px;
	}

	&:hover {
		.o1{
			fill: rgba(#FDED47, 0.3);
		}
		
		.o2 {
			stroke-dashoffset: -420;
		}
	}
}
// VARIABLES
$color-header: #0d0d0d;
$color-text: #404040;

$transition: .2s;


// MAIN CONTENT

.content {
  width: 100%;
  margin-top: -50px;
}

.section1 {
  .sectioncontent {
   
    padding: 30px;
  margin: 0 20% 0 20%;
  text-align: center;

}
h1 {
  margin-top: 60px;
  
  margin-bottom: 30px;
 
}
h2 {
  margin-bottom: 30px;
  
}
p {
  font-size: 1.1rem;
}
.grid {
  h2 {
    text-align: center;
  }
  text-align: left;
  display: flex;
  justify-content: space-around;
  
  &__item {
    width: 100%;
    margin: 10px;
    background-color: #fff;
    border-radius: .4rem;
    overflow: hidden;

    cursor: default;
    transition: $transition;
    
    &:hover {
     
      transform: translateY(-.5%);
      box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
    }
  }
}

.card {
  height: 100%;
  &__content {
    padding: 2rem 2rem;
  }
  
  &__header {
    font-size: 1.2rem;
    font-weight: 600;
    color: $color-header;
    margin-bottom: 1.5rem;
  }
  
  &__text {
    font-size: 0.9rem;
    letter-spacing: .1rem;
    line-height: 1.9rem;
    color: darken($color-text, 1%);
    margin-bottom: 2.5rem;
  }
  
 
}

@media (max-width: 1400px) {

  .sectioncontent {
    padding: 20px;
    margin: 0 0% 0 0%;
  
}

  .grid {
    flex-direction: column;
    align-items: center;
    &__item {
      width: 100%;
    }
  }
} 

}

.section2 {
  width: 70%;
  margin: 0 auto;

  .sectioncontent2 {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    text-align: center;
    h1 {
      margin-bottom: 20px;
      letter-spacing: .1rem;
    }
    h4,h5 {
      margin-bottom: 10px;
    }
}
}

@media (max-width: 1200px) { 
  .section2 {
  width: 100%;


  .sectioncontent2 {
    h4 {
      margin-bottom: 30px;
    }
    .button {
      margin-bottom: 30px;
    }
   
    padding: 20px;
  
}
}
}
.button {
  margin-top: 30px;
  display: inline-flex;
  align-items: center;
  background: var(--theme-on-primary);
  box-shadow: 0 3px 2px 0 rgba(0,0,0,0.1);
  border-radius: 5px;
  height: 45px;
  padding: 0 30px;
  color: black;
  font-family: Lato, Arial, sans-serif;
  text-transform: uppercase;
  text-decoration: none;

  
  &:hover {
    background: darken(#FDED47 ,10%);
    box-shadow: 0 4px 17px rgba(0,0,0,0.2);
    transform: translate3d(0, -2px, 0);
  }
  &:active {
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.1);
    transform: translate3d(0, 1px, 0);
  }
}



// CodePen Hack


</style>