<template>

  <header :class="{ 'scrolled-nav': scrolledNav }">
    <nav>
      <div class="branding" @click="goToHome()">
        <img v-show="!mobile" class="headerImg" src="@/assets/logo-tronando-empty-2.png" alt="" />
        <span :class="{ 'mobileImg': mobile }">Tronando</span>

      </div>
      <ul v-show="!mobile" class="navigation">
        <li v-for="(item, index) in navList" :key="index">
          <Router-link
            v-if="item.title != 'Merch'"
            class="link"
            :to="item.slug" :class="{disabled: item.disabled}"
            >{{ item.title }}</Router-link
          >
          <a
            v-if="item.title == 'Merch'"
            :href="item.slug"
            class="link"
            target="”_blank”"
            >{{ item.title }}</a
          >
        </li>
        <div class="subButton" v-show="scrolledNav" v-on:click="checkPage()">
          <li>
          <Router-link
            class="link"
            to="/subscribe"
            >SUSCRIBETE</Router-link
          >
        </li>
        </div>
        
      </ul>



      <div class="icon">
        <i
          @click="toggleMobileNav"
          v-show="mobile"
          class="fa-solid fa-bars"
          :class="{ 'icon-active': mobileNav }"
        ></i>
      </div>

      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav">
          <li class="navImage">Tronando ⚡</li>
          <li v-for="(item, index) in navList" :key="index">
           
            <Router-link
            v-if="item.title != 'Merch'"
              class="link"
              :to="item.slug"
              @click="toggleMobileNav()" :class="{disabled: item.disabled}"
              >- {{ item.title }}</Router-link
            >
            <a
            v-if="item.title == 'Merch'"
              :href="item.slug"
              class="link"
              target="”_blank”"
              @click="toggleMobileNav()"
              >- {{ item.title }}</a
            >
          </li>
          <li class="navSocial">
            
      <a v-for="(social, index) in socialLinks" :key="index"
        class="btn btn-link btn-floating btn-lg m-1"
        :href="social.url"
        role="button"
        ><i :class="social.icon"></i></a>

          </li>
        </ul>
        
      </transition>
    </nav>
  </header>

</template>

<script>
import { nav } from "@/config/nav";
import {navSocials} from '../../config/socials'

export default {
  name: "Navbar",
  data() {
    return {
      navList: nav,
      scrolledNav: null,
      mobile: null,
      mobileNav: false,
      windowWidth: null,
      socialLinks : navSocials,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
 

  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    checkPage() {if (this.$route.name == 'home') {
      window.scrollTo(0, 0)
    }},
    goToHome(){this.$router.push({ name: "home" })},
    updateScroll() {
      const scrollPosition = window.scrollY;
      
      if (scrollPosition > 50) {
        if (!this.mobile)
          this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
      
      return;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1200) {
        this.mobile = true;
        if(this.mobileNav) {
          this.toggleMobileNav()
        }
        
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
header {
  transition: 0.8s ease all;

  z-index: 99;
  top: 0;
  width: 100%;
  position: fixed;
  transition: 0.5 ease all;
  color: var(--text-color);
  overflow: hidden;
  box-shadow: 0 4px 6px -6px black;
  letter-spacing: 1.4px;

  nav {
    padding: 3px;
    width: 100%;
    display: flex;
   
    flex-direction: row;
    margin: 0 auto;
    align-content: center;
    justify-content: center;
    background-color: var(--back-color);
    transition: 0.5s ease all;
    .subButton {
      border-left: 1px black solid;
      border-right: 1px black solid;
      border-bottom: 1px black solid;
      border-radius: 0px 0px 20px 20px ;
      background-color: var(--theme-on-primary);
      :hover {
        cursor: pointer;
        
        border-radius: 0px 0px 20px 20px ;
        .link {
          color: black;
       text-shadow: var(--theme-on-primary) 1px 0 10px;
      
        
      }
      }
      
    }
    img {
      filter: drop-shadow(2px 1px 1px rgb(0,0,0, 0.2));
    }
    ul,
    .link {
      font-size: 40px;
      font-weight: 700;
      color: var(--text-color);
      list-style: none;
      text-decoration: none;
    }

    li {
      text-transform: uppercase;
      padding: 0 20px 0 20px;
    }
    .link {
      font-size: 14px;
      transition: 0.5 ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;

      &:hover {
        color: var(--theme-on-primary);
        font-weight: bold;
        border-bottom: 1px var(--theme-on-primary) solid;

      }
    }

    .branding {
      cursor: pointer;
      display: flex;
      align-items: center;
      
 
      .headerImgMobile {
        width: 2rem;
        padding: 20px;
        transition: 0.5s ease all;
        
      }

      .headerImg {
        width: 50px;
        margin-left: 0px;
        transition: 0.5s ease all;
      }
     
    }

    .navigation {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      transition: 0.8s ease all;
      
    }
    .icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 24px;
      height: 100%;
      i {
        cursor: pointer;
        font-size: 24px;
        transition: 0.8s ease all;
      }
    }
    .mobileImg {
      margin: 10px;
      font-size: 1.1rem;
      letter-spacing: 0.4rem;
      font-weight: 800;
    }
    .icon-active {
      transform: rotate(90deg);
    }
    // Mobile menu
    .dropdown-nav {
      background-color: var(--back-color);
      li {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      display: flex;
      flex-direction: column;
     
				box-shadow: 5px 0 5px -5px #333;
			
      position: fixed;
      width: 100%;
      max-width: 300px;
      height: 100vh;
     
      top: 10px;
      left: 0;
      font-size: 10px;
      
      color: var(--text-color);
 
       
      .navImage {
        font-size: 20px;
        padding: 20px;
        margin-top: -10px;
        background: var(--theme-on-primary);
        color: black;
        margin-bottom: 15px;
        
        
      }
      .navSocial {
        display: flex;
        padding-top: 10px;
        justify-content: space-around;
        margin-top: auto;
     
        z-index: 100;
     
				box-shadow: 0 -4px 4px -4px #333;
	

      }
      
      li {
        margin-left: 0;
        
        margin-bottom: 15px;
        a {
        color: var(--theme-on-primary);
        
       }
        .link {
          color: var(--text-color);
        }
      }
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
      transition: 1s ease all;
    }

    .mobile-nav-enter-from,
    .mobile-nav-leave-to {
      transform: translateX(-250px);
    }

    .mobile-nav-enter-to {
      transform: translateX(0);
    }
  }
}

.scrolled-nav {
  box-shadow: 0 4px 6px 1px rgba(0,0,0, 0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
  background: rgb(254, 254, 254);

  nav {
    padding: 0px;
    transition: 0.5s ease all;
    .navigation {
      justify-content: center;
     
    }
      img,span {
      display: none;
    }
  }
}
</style>